import type {
    input,
    ZodBoolean,
    ZodDefault,
    ZodEffects,
    ZodEnum,
    ZodNativeEnum,
    ZodNullable,
    ZodNumber,
    ZodObject,
    ZodOptional,
    ZodRawShape,
    ZodSchema,
    ZodString,
    ZodTypeAny
} from 'zod'
import type { Prettify } from './utility'
import type { MaybePromise } from 'rollup'
import type { FormAutoSubmitOn, FormInitialData, FormSubmitOrigin } from '../app/composables/form'
import type { DeepReadonly } from 'vue'
import type { ApiErrorData } from '@composable-api/api.response-error'

// type FormInput = 'color' | 'date' | 'datetime-local' | 'file' | 'month'| 'range' | 'time' | 'week' | TextFormInput

export type TextFormInput = 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'textarea'

export type FormAutocomplete = 'on' | 'off' | 'name' | 'given-name' | 'family-name' | 'email' | 'username' | 'new-password' | 'current-password'
    | 'one-time-code' | 'organization-title' | 'organization' | 'street-address' | 'address-line1' | 'address-line2'
    | 'address-line3' | 'country' | 'country-name' | 'postal-code' | 'cc-name' | 'cc-number' | 'cc-exp' | 'cc-exp-month'
    | 'cc-exp-year' | 'cc-csc' | 'cc-type' | 'transaction-currency' | 'transaction-amount' | 'language' | 'bday'
    | 'bday-day' | 'bday-month' | 'bday-year' | 'sex' | 'tel' | 'tel-country-code' | 'tel-national' | 'tel-area-code'
    | 'tel-local' | 'tel-local-prefix' | 'tel-local-suffix' | 'tel-extension' | 'impp' | 'url' | 'photo' | 'address-level1'
    | 'address-level2' | 'address-level3' | 'address-level4' | ShippingAndBillingFormAutocomplete

type ShippingAndBillingFormAutocomplete =  'shipping address-line1' | 'billing address-line1' | 'shipping address-level2' | 'billing address-level2'
    | 'shipping postal-code' | 'billing postal-code' | 'shipping country-name' | 'billing country-name'

export enum ModelAction {
    CREATE = 'create',
    EDIT = 'edit',
    DELETE = 'delete',
}

export type FormObject<TSchema extends ZodSchema> = {
    schema: ComputedRef<TSchema>
    globalError: Readonly<Ref<FormGlobalErrorValue>>
    isSubmitting: Readonly<Ref<boolean>>

    formDataObject: Ref<FormDataObject<TSchema>>
    formValues: Ref<input<TSchema>>
    initialData: ComputedRef<FormInitialData<TSchema> | undefined>
    formFieldErrors: DeepReadonly<Ref<FormErrors<TSchema>>>

    setFormValues: (values: FormInitialData<TSchema>) => void
    setFieldErrors: (errors: FormErrors<TSchema>) => void
    setGlobalError: (error: FormGlobalErrorValue) => void
    resetFieldError: (field: keyof input<TSchema>) => void
    resetForm: (ignoreInitialData?: boolean) => void
    resetFormData: (ignoreInitialData?: boolean) => void
    validateForm: () => Promise<boolean>
    submitRaw: () => Promise<void>

    _private: {
        _handleSubmit: (origin?: FormSubmitOrigin) => MaybePromise<void>
        _callBeforeSubmit: (callback: () => MaybePromise<boolean | undefined>) => void
        _autoSubmitOn: MaybeRefOrGetter<FormAutoSubmitOn | undefined>
        _fieldErrors: DeepReadonly<Ref<FormErrors<TSchema>>>
    }
}

export type GlobalFormError = {
    id: string | undefined  // is undefined when there's no error
    message: string | null
    type?: ApiErrorData['type'] | null
}

export type FormGlobalErrorValue = { message: GlobalFormError['message'], type?: GlobalFormError['type'] } | null
export type FormErrorValue = string | null
export type FormErrors<T extends ZodSchema> = Partial<Record<keyof input<T>, FormErrorValue>>

export type FormEventType = 'blur' | 'change' | '_set-val'

export type FormEvent<T extends FormFieldObject<any> = any> = {
    type: FormEventType
    __f: T['__f']
}

export type FormFieldObject<T> = {
    __f: string
    __r: boolean,
    __v: T | undefined
}

export type NestedZodObjectOrEffects<T> =
  T extends ZodObject<ZodRawShape> ? T :
      T extends ZodEffects<infer U> ? T | NestedZodObjectOrEffects<U> :
          never

export type BaseZodSchemaFromNestedType<T> =
    T extends ZodObject<ZodRawShape> ? T :
        T extends ZodEffects<infer U> ? BaseZodSchemaFromNestedType<U> : never

export type BaseZodType = ZodString | ZodNumber | ZodBoolean | ZodEnum<any> | ZodNativeEnum<any>
export type SupportedZodTypes = ZodOptional<any> | ZodDefault<any> | ZodNullable<any> | BaseZodType | ZodObject<ZodRawShape>

export type WrapperZodType<T extends ZodTypeAny> = ZodOptional<T> | ZodDefault<T> | ZodNullable<T>

export type NestedZodTypeInDefaultOrOptional<T> =
    T extends WrapperZodType<infer U> ? U
        : T extends BaseZodType | ZodObject<ZodRawShape> ? T :
            never

export type BaseZodTypeFromNestedType<T> =
    T extends WrapperZodType<infer U> ? BaseZodTypeFromNestedType<U> :
        T extends BaseZodType ? T :
            never

export type NormalizeArrays<T> = T extends Array<any> ? T[number] : T

export type PrimitiveTypeFromZodType<T> =
    T extends ZodString ? string :
        T extends ZodNumber ? number :
            T extends ZodBoolean ? boolean :
                T extends ZodEnum<infer U> ? NormalizeArrays<U> :
                    T extends ZodNativeEnum<infer U> ? U[keyof U] :
                        never

export type BaseFormFieldObject<T extends PrimitiveTypeFromZodType<BaseZodType>>  = Omit<FormFieldObject<T>, '__f'>

type GetZodObjectTypes<T> = T extends ZodObject<ZodRawShape> ? ReturnType<T['_def']['shape']> : never

export type FormDataObject<T extends ZodSchema> = Required<{
    [key in keyof input<T>]: GetZodObjectTypes<T>[key] extends ZodObject<ZodRawShape>
        // recursively handle ZodObjects inside the schema
        ? Prettify<
            FormDataObject<GetZodObjectTypes<T>[key]>
        >
        // handle individual fields
        : Prettify<
            FormFieldObject<
                PrimitiveTypeFromZodType<
                    BaseZodTypeFromNestedType<
                        GetZodObjectTypes<T>[key]
                    >
                >
            >
        >
}>

export type FormDataObjectFields<T extends FormDataObject<any>> = {
    [K in keyof T]: T[K] extends FormFieldObject<any>
        // use `string` type to simulate the path to the form field
        ? string
        // handle nested `z.object`s recursively
        : T[K] extends FormDataObject<any> ? FormDataObjectFields<T[K]> :
            never
}
