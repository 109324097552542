<template>
    <form ref="formEl" class="sim-form" @submit.prevent="handleSubmit()">
        <slot v-bind="slotData" />
    </form>
</template>

<script lang="ts" setup generic="TSchema extends ZodSchema">
import type { output, ZodSchema } from 'zod'
import type { FormObject } from '@core-types/form'
import type { MaybePromise } from 'rollup'
import type {
    FormAutoSubmitOn,
    FormErrorType,
    FormInitialData,
    FormResetOn,
    FormSubmitOrigin
} from '../../../../composables/form'

const {
    form: _form,
    schema,
    onSubmit,
    initialData,
    resetOn,
    autoSubmitOn,
    validateOn,
    notifyOnError,
} = defineProps<{
    form?: FormObject<TSchema>
    schema?: TSchema
    onSubmit?: (formData: output<TSchema>) => MaybePromise<void>
    initialData?: FormInitialData<TSchema>
    resetOn?: FormResetOn
    autoSubmitOn?: FormAutoSubmitOn
    validateOn?: FormSubmitOrigin | FormSubmitOrigin[]
    notifyOnError?: FormErrorType
}>()

const emit = defineEmits<{
    /**
     * Emitted after the form submit request is successfully resolved.
     */
    submitted: []
}>()

const form = _form ?? useForm({
    schema: () => schema ?? z.object({}) as unknown as TSchema,
    onSubmitReactive: () => onSubmit ?? (() => {}),
    initialData: () => initialData,
    resetOn: () => resetOn,
    autoSubmitOn: () => autoSubmitOn,
    validateOn: () => validateOn,
    notifyOnError: () => notifyOnError,
})

const formEl = useTemplateRef<HTMLFormElement>('formEl')
const formIdError = useId()

const globalFormError = computed<GlobalFormError>(() => ({
    id: form.globalError.value && formIdError ? formIdError : undefined,
    message: form.globalError.value?.message ?? null,
    type: form.globalError.value?.type,
}))

const beforeSubmitCallbacks = new Set<() => MaybePromise<boolean | void>>()
function registerCallback(event: 'before-submit', callback: () => MaybePromise<boolean | void>) {
    if (event === 'before-submit') {
        beforeSubmitCallbacks.add(callback)
    }
}

form._private._callBeforeSubmit(async () => {
    const results = await Promise.allSettled([...beforeSubmitCallbacks].map((callback) => callback()))
    if (results.some((result) => result.status === 'rejected')) return false
    return !results.some((result) => result.status === 'fulfilled' && result.value === false)
})

const didFormChange = ref<boolean>(false)
// TODO: make reactive in the future?
if (toValue(form._private._autoSubmitOn) === 'form-change') {
    const { focused } = useFocusWithin(formEl)

    /*
        TODO: improve logic for checking whether the form changed or not.
        Initial idea: save the state of the formData object when the form is focused
        and compare it to the state at focus out

        Currently, if for example the user were to change a toggle a checkbox twice
        (false -> true -> false) and then blurred the form, it would still count as
        a change and would auto-submit.
     */
    watch(focused, (newVal, oldVal) => {
        if (newVal || !oldVal) return
        // *-*- form blur event -*-*
        // TODO: maybe add function handlers?

        // skip if the form values didn't change
        if (!didFormChange.value) return

        // *-*- form change event -*-*
        // TODO: maybe add function handlers?

        if (toValue(form._private._autoSubmitOn) === 'form-change') {
            handleSubmit('form-change')
        }
    })
}

const debouncedHandleSubmit = useDebounceFn(handleSubmit, 150)
const bus = useEventBus<FormEvent>(Symbol('form-bus'))
const registeredBusPromise = import.meta.client ? new Promise<void>(resolve => {
    onMounted(() => {
        bus.on((event) => {
            if (event.type === '_set-val' && toValue(form._private._autoSubmitOn)) {
                handleSubmit('change')
                return
            }

            if (event.type !== 'change') return

            didFormChange.value = true
            if (toValue(form._private._autoSubmitOn) === 'change') {
                handleSubmit('change')
            } else if (toValue(form._private._autoSubmitOn) === 'change-debounced') {
                debouncedHandleSubmit('change')
            }
        })

        resolve()
    })
}) : Promise.resolve()

onBeforeUnmount(() => {
    beforeSubmitCallbacks.clear()
})

const slotData = computed(() => ({
    formData: form.formDataObject.value,
    formValues: form.formValues.value,
    formFieldErrors: form.formFieldErrors.value,
    isFormSubmitting: form.isSubmitting.value,
    globalFormError: globalFormError.value,
}))

async function handleSubmit(...args: Parameters<typeof form._private._handleSubmit>) {
    await form._private._handleSubmit(...args)
    emit('submitted')
}

async function validate() {
    return formEl.value?.reportValidity() || await form.validateForm()
}

async function submit() {
    // call the native HTML form validation
    if (formEl.value?.reportValidity() === false) {
        return false
    }

    await handleSubmit()

    return true
}

provide<CoreUiFormProvide<TSchema>>(SymbolCoreUiForm, {
    formErrors: form._private._fieldErrors,
    resetFormError: form.resetFieldError,
    registerCallback: registerCallback,
    bus: bus,
    registeredBusPromise: registeredBusPromise,
})

defineExpose({
    submit,
    validate,
})

</script>

<style lang="scss" scoped>

// wrapped in last-child to only disable the margin at the end of all forms
.sim-form:last-child {
    :slotted(.sim-form-row) {
        &:last-child {
            margin-bottom: 0;
        }
    }

    :slotted(.sim-form-section) {
        &:last-child {
            .sim-form-row:last-child {
                margin-bottom: 0;
            }
        }
    }
}

</style>
