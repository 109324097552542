import { type ZodTypeAny, ZodType, type ZodErrorMap, ZodIssueCode, ZodParsedType, util, type ZodEnum } from 'zod'
import type { MaybeRefOrGetter } from 'vue'
import { type Composer } from 'vue-i18n'

export function zRequiredIf<T extends ZodTypeAny | Record<string, ZodTypeAny>>(
    type: T,
    isRequired: MaybeRefOrGetter<boolean>
    // @ts-ignore
    // TODO: fix type and maybe use T[K]['_def'] instead of '_input'
): T extends ZodTypeAny ? ZodType<T['_output'], T['_input']> : { [K in keyof T]: ZodType<T[K]['_output'], T[K]['_input']> } {
    const isRequiredValue = toValue(isRequired)

    if (type?._def?.typeName) {
        // Handling when type is ZodTypeAny
        // @ts-ignore
        return (isRequiredValue ? type : type.optional()) as T extends ZodTypeAny ? ZodType<T['_output'], T['_input'], T['_output']> : never
    }

    // Handling when type is a record of string keys to ZodTypeAny values
    // @ts-ignore
    const result: Partial<{ [K in keyof T]: ZodType<T[K]['_output'], T[K]['_input'], T[K]['_output']> }> = {}
    for (const key in type) {
        const value = type[key]
        // @ts-ignore
        if (!(value?._def?.typeName)) {
            continue // Or handle error
        }
        // @ts-ignore
        result[key] = isRequiredValue ? value : value.optional()
    }
    // @ts-ignore
    return result as T extends Record<string, ZodTypeAny> ? { [K in keyof T]: ZodType<T[K]['_output'], T[K]['_input'], T[K]['_output']> } : never
}

export function createZodErrorMap(t: Composer['t']): ZodErrorMap {
    return (issue, _ctx) => {
        let message: string
        switch (issue.code) {
            case ZodIssueCode.invalid_type:
                if (issue.received === ZodParsedType.undefined) {
                    message = t('_core_simploshop.zod.field_required')
                } else if (issue.expected === ZodParsedType.string) {
                    message = t('_core_simploshop.zod.field_must_be_string')
                } else {
                    message = t('_core_simploshop.zod.expected_but_received', [issue.expected, issue.received])
                }
                break
            case ZodIssueCode.invalid_literal:
                message = t('_core_simploshop.zod.invalid_value_expected', [JSON.stringify(
                    issue.expected,
                    util.jsonStringifyReplacer
                )])
                break
            case ZodIssueCode.unrecognized_keys:
                message = `Unrecognized key(s) in object: ${util.joinValues(
                    issue.keys,
                    ', '
                )}`
                break
            case ZodIssueCode.invalid_union:
                message = t('_core_simploshop.zod.invalid_input')
                break
            case ZodIssueCode.invalid_union_discriminator:
                message = t('_core_simploshop.zod.invalid_value_expected', [util.joinValues(
                    issue.options
                )])
                break
            case ZodIssueCode.invalid_enum_value:
                message = t('_core_simploshop.zod.invalid_value_expected_received', [util.joinValues(
                    issue.options
                ), issue.received])
                break
            case ZodIssueCode.invalid_arguments:
                message = `Invalid function arguments`
                break
            case ZodIssueCode.invalid_return_type:
                message = `Invalid function return type`
                break
            case ZodIssueCode.invalid_date:
                message = t('_core_simploshop.zod.invalid_date')
                break
            case ZodIssueCode.invalid_string:
                if (typeof issue.validation === 'object') {
                    if ('includes' in issue.validation) {
                        message = `Invalid input: must include "${issue.validation.includes}"`

                        if (typeof issue.validation.position === 'number') {
                            message = `${message} at one or more positions greater than or equal to ${issue.validation.position}`
                        }
                    } else if ('startsWith' in issue.validation) {
                        message = t('_core_simploshop.zod.must_start_with', [issue.validation.startsWith])
                    } else if ('endsWith' in issue.validation) {
                        message = t('_core_simploshop.zod.must_end_with', [issue.validation.endsWith])
                    } else {
                        util.assertNever(issue.validation)
                    }
                } else if (issue.validation !== 'regex') {
                    message = `${t('_core_simploshop.zod.invalid_input')}: ${issue.validation}`
                } else {
                    message = t('_core_simploshop.zod.invalid_input')
                }
                break
            case ZodIssueCode.too_small:
                if (issue.type === 'array') {
                    message = `Array must contain ${
                        issue.exact ? 'exactly' : issue.inclusive ? `at least` : `more than`
                    } ${issue.minimum} element(s)`
                }
                else if (issue.type === 'string') {
                    if (issue.exact) {
                        message = `${t('_core_simploshop.zod.exact_length')} ${t('_core_simploshop.zod.char_length', [issue.minimum], issue.minimum as number)}`
                    } else if (issue.inclusive) {
                        message = `${t('_core_simploshop.zod.minimum_length')} ${t('_core_simploshop.zod.char_length', [issue.minimum], issue.minimum as number)}`
                    } else {
                        message = `${t('_core_simploshop.zod.over_length')} ${t('_core_simploshop.zod.char_length', [issue.minimum], issue.minimum as number)}`
                    }
                }
                else if (issue.type === 'number') {
                    if (issue.exact) {
                        message = t('_core_simploshop.zod.field_exactly', [issue.minimum])
                    } else if (issue.inclusive) {
                        message = t('_core_simploshop.zod.field_greater_inclusive', [issue.minimum])
                    } else {
                        message = t('_core_simploshop.zod.field_greater', [issue.minimum])
                    }
                }
                else if (issue.type === 'date') {
                    if (issue.exact) {
                        message = t('_core_simploshop.zod.field_exactly', [new Date(Number(issue.minimum))])
                    } else if (issue.inclusive) {
                        message = t('_core_simploshop.zod.field_greater_inclusive', [new Date(Number(issue.minimum))])
                    } else {
                        message = t('_core_simploshop.zod.field_greater', [new Date(Number(issue.minimum))])
                    }
                }
                else {
                    message = t('_core_simploshop.zod.invalid_input')
                }
                break
            case ZodIssueCode.too_big:
                if (issue.type === 'array') {
                    message = `Array must contain ${
                        issue.exact ? `exactly` : issue.inclusive ? `at most` : `less than`
                    } ${issue.maximum} element(s)`
                }
                else if (issue.type === 'string') {
                    if (issue.exact) {
                        message = `${t('_core_simploshop.zod.exact_length')} ${t('_core_simploshop.zod.char_length', [issue.maximum], issue.maximum as number)}`
                    } else if (issue.inclusive) {
                        message = `${t('_core_simploshop.zod.maximum_length')} ${t('_core_simploshop.zod.char_length', [issue.maximum], issue.maximum as number)}`
                    } else {
                        message = `${t('_core_simploshop.zod.under_length')} ${t('_core_simploshop.zod.char_length', [issue.maximum], issue.maximum as number)}`
                    }
                }
                else if (issue.type === 'number' || issue.type === 'bigint') {
                    if (issue.exact) {
                        message = t('_core_simploshop.zod.field_exactly', [issue.maximum])
                    } else if (issue.inclusive) {
                        message = t('_core_simploshop.zod.field_less_inclusive', [issue.maximum])
                    } else {
                        message = t('_core_simploshop.zod.field_less', [issue.maximum])
                    }
                }
                else if (issue.type === 'date') {
                    if (issue.exact) {
                        message = t('_core_simploshop.zod.field_exactly', [new Date(Number(issue.maximum))])
                    } else if (issue.inclusive) {
                        message = t('_core_simploshop.zod.field_less_inclusive', [new Date(Number(issue.maximum))])
                    } else {
                        message = t('_core_simploshop.zod.field_less', [new Date(Number(issue.maximum))])
                    }
                }
                else {
                    message = t('_core_simploshop.zod.invalid_input')
                }
                break
            case ZodIssueCode.custom:
                message = t('_core_simploshop.zod.invalid_input')
                break
            case ZodIssueCode.invalid_intersection_types:
                message = `Intersection results could not be merged`
                break
            case ZodIssueCode.not_multiple_of:
                message = t('_core_simploshop.zod.number_multiple', [issue.multipleOf])
                break
            case ZodIssueCode.not_finite:
                message = t('_core_simploshop.zod.number_finite')
                break
            default:
                message = _ctx.defaultError
                util.assertNever(issue as never)
        }
        return { message }
    }
}

export function zEnum<T extends Record<any, any>>(obj: T): T extends Record<any, infer U> ? ZodEnum<[Extract<U, string>, ...Extract<U, string>[]]> : never {
    return z.enum(Object.values(obj) as any) as any
}
